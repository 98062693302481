// Copyright (C) 2019 Pepperdata Inc. - All rights reserved.
// @flow

import _ from "lodash";
import React from "react";
import { CONTAINER_CREATE, CREATE_FORM_NAMES } from "../constants";
import Button from "../ui-elements/button";
import Space from "../ui-elements/space";
import TextInput from "../ui-elements/text-input";
import GoBackHeader from "./go-back-header";

type Props = {
  onSubmit: (SyntheticEvent<HTMLFormElement>) => Promise<any> | void,
  updateFirstName: (SyntheticKeyboardEvent<HTMLInputElement>) => void,
  updateLastName: (SyntheticKeyboardEvent<HTMLInputElement>) => void,
  clearFirstName: () => void,
  clearLastName: () => void,
  backButtonOnClick: () => void,
  firstName: string,
  lastName: string,
  containerFlow: string,
  uiFlow: string,
  isLoading: boolean,
  emailAddress: string,
};

type State = {
  validatorResults: {
    firstNameErrorDescription?: string,
    lastNameErrorDescription?: string,
  },
};

class CreateAccountFormName extends React.Component<Props, State> {
  isFirstNameTouched: boolean;
  isLastNameTouched: boolean;
  runValidatorsTimeoutId: ?TimeoutID;
  textInputRef: { current: null | React$ElementRef<typeof TextInput> };
  textInputRefLastName: { current: null | React$ElementRef<typeof TextInput> };

  constructor(props: Props) {
    super(props);

    this.state = {
      validatorResults: {
        firstNameErrorDescription: "",
        lastNameErrorDescription: "",
      },
    };

    this.isFirstNameTouched = false;
    this.isLastNameTouched = false;
    this.runValidatorsTimeoutId = null;
    this.textInputRef = React.createRef();
    this.textInputRefLastName = React.createRef();
  }

  hasErrors = () => {
    const { validatorResults } = this.state;
    const { firstName } = this.props;
    const { firstNameErrorDescription } = validatorResults;

    if (
      firstName.length === 0 ||
      (firstNameErrorDescription && firstNameErrorDescription.length > 0)
    ) {
      return true;
    }
    return false;
  };

  onSubmit = (event: SyntheticEvent<HTMLFormElement>) => {
    event.preventDefault();
    const parentOnSubmit = this.props.onSubmit;

    // Force run validators by setting inputs to be touched
    this.isFirstNameTouched = true;
    this.isLastNameTouched = true;

    this.runValidators(() => {
      // only run parent's onSubmit function if there are no validation errors
      if (this.hasErrors() === false) {
        parentOnSubmit(event);
      }
    });
  };

  updateFirstName = (event: SyntheticKeyboardEvent<HTMLInputElement>) => {
    const parentUpdateFirstName = this.props.updateFirstName;
    this.isFirstNameTouched = true;
    parentUpdateFirstName(event);
  };

  updateLastName = (event: SyntheticKeyboardEvent<HTMLInputElement>) => {
    const parentUpdateLastName = this.props.updateLastName;
    this.isLastNameTouched = true;
    parentUpdateLastName(event);
  };

  clearNames = () => {
    this.props.clearFirstName();
    this.props.clearLastName();
  };

  validateNames = () => {
    const { firstName, lastName } = this.props;

    if (this.isFirstNameTouched === false && this.isLastNameTouched === false) {
      return {};
    }

    const errors = {};
    if (firstName.length === 0 && this.isFirstNameTouched) {
      errors["firstNameErrorDescription"] = "First name is required";
    }
    if (lastName.length === 0 && this.isLastNameTouched) {
      errors["lastNameErrorDescription"] = "Last name is required";
    }

    return errors;
  };

  resetValidatorErrors = () => {
    this.setState({
      validatorResults: {
        firstNameErrorDescription: "",
      },
    });
  };

  // callback (function): Place any code that depends on the
  // validation errors in the callback function so that it will
  // run after the errors has been set to state. Reason for the
  // callback is because setState is asynchronous.
  runValidators = (callback?: () => void) => {
    const validatorResult = this.validateNames();
    const hasError = Object.keys(validatorResult).length > 0;
    if (hasError) {
      const newValidatorResults = {
        ...this.state.validatorResults,
        ...validatorResult,
      };
      if (callback !== undefined) {
        this.setState({ validatorResults: newValidatorResults }, callback);
      } else {
        this.setState({ validatorResults: newValidatorResults });
      }
    } else {
      if (callback !== undefined) {
        callback();
      }
    }
  };

  runValidatorsWithDelay = () => {
    this.resetValidatorErrors();

    // Prevent execution of past timeout
    if (this.runValidatorsTimeoutId !== null) {
      clearTimeout(this.runValidatorsTimeoutId);
    }

    // Run validators after a user stops typing by giving
    // them 500ms delay between each key stroke
    this.runValidatorsTimeoutId = setTimeout(() => {
      this.runValidators();
    }, 500);
  };

  focusOnInput = () => {
    if (this.textInputRef.current !== null) {
      this.textInputRef.current.focusOnInput();
    }
  };

  isThisComponentInView() {
    const { containerFlow, uiFlow } = this.props;
    return containerFlow === CONTAINER_CREATE && uiFlow === CREATE_FORM_NAMES;
  }

  goBackAndResetName = () => {
    const { clearFirstName, backButtonOnClick } = this.props;
    clearFirstName();
    this.clearNames();
    this.resetValidatorErrors();
    backButtonOnClick();
  };

  componentDidUpdate(prevProps: Props) {
    const prevFirstName = prevProps.firstName;
    const prevLastName = prevProps.lastName;
    const { firstName, lastName } = this.props;

    if (prevFirstName !== firstName) {
      this.runValidatorsWithDelay();
    }
    if (prevLastName !== lastName) {
      this.runValidatorsWithDelay();
    }

    // when this UI is being viewed, focus on the input field
    const { containerFlow, uiFlow } = this.props;
    if (
      this.isThisComponentInView() &&
      (prevProps.containerFlow !== containerFlow || prevProps.uiFlow !== uiFlow)
    ) {
      this.focusOnInput();
    }
  }

  render() {
    const { firstName, lastName, clearFirstName, clearLastName, isLoading } =
      this.props;
    const buttonText = "NEXT";
    const componentVisibility = this.isThisComponentInView()
      ? "visible"
      : "hidden";

    const { firstNameErrorDescription, lastNameErrorDescription } =
      this.state.validatorResults;

    const shouldDisableSubmitButton =
      firstName.length === 0 ||
      lastName.length === 0 ||
      _.keys(this.validateNames()).length > 0;

    return (
      <div
        className="body-section pd-create-form-names"
        style={{ visibility: componentVisibility }}
      >
        <GoBackHeader
          goBackActionFunc={this.goBackAndResetName}
          textToDisplay={this.props.emailAddress}
          disabled={false}
        />
        <form
          className="input-section"
          onSubmit={this.onSubmit}
          autoComplete="off"
        >
          <Space height="28px" />
          <TextInput
            ref={this.textInputRef}
            value={firstName}
            label="FIRST NAME"
            onChange={this.updateFirstName}
            onClear={clearFirstName}
            errorDescription={firstNameErrorDescription}
            shouldFocusAfterTransitionDuration={true}
            disabled={isLoading}
          />

          <TextInput
            ref={this.textInputRefLastName}
            value={lastName}
            label="LAST NAME"
            id={"create-account-last-name"}
            onChange={this.updateLastName}
            onClear={clearLastName}
            errorDescription={lastNameErrorDescription}
            shouldFocusAfterTransitionDuration={false}
            disabled={isLoading}
          />

          <Space height="20px" />

          <Button
            text={buttonText}
            buttonType="submit"
            isLoading={isLoading}
            disabled={shouldDisableSubmitButton}
          />
        </form>
      </div>
    );
  }
}

export default CreateAccountFormName;
