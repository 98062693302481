// Copyright (C) 2019 Pepperdata Inc. - All rights reserved.
// @flow

import React from "react";
import { CONTAINER_CREATE, CREATE_FORM_ACTIVATE } from "../constants";
import Button from "../ui-elements/button";
import Space from "../ui-elements/space";
import TextInput from "../ui-elements/text-input";

type Props = {
  onSubmit: (SyntheticEvent<HTMLFormElement>) => void,
  updateActivateEmailAddress: (
    SyntheticKeyboardEvent<HTMLInputElement>
  ) => void,
  clearActivateEmailAddress: () => void,
  activateEmailAddress: string,
  uiFlow: string,
  containerFlow: string,
};

type State = {};

class CreateAccountFormActivate extends React.Component<Props, State> {
  textInputRef: { current: null | React$ElementRef<typeof TextInput> };

  constructor(props: Props) {
    super(props);

    this.textInputRef = React.createRef();
  }

  focusOnInput = () => {
    if (this.textInputRef.current !== null) {
      this.textInputRef.current.focusOnInput();
    }
  };

  isThisComponentInView() {
    const { containerFlow, uiFlow } = this.props;
    return (
      containerFlow === CONTAINER_CREATE && uiFlow === CREATE_FORM_ACTIVATE
    );
  }

  componentDidUpdate(prevProps: Props) {
    // when this UI is being viewed, focus on the input field
    const { uiFlow } = this.props;
    if (this.isThisComponentInView() && prevProps.uiFlow !== uiFlow) {
      this.focusOnInput();
    }
  }

  render() {
    const {
      onSubmit,
      updateActivateEmailAddress,
      clearActivateEmailAddress,
      activateEmailAddress,
    } = this.props;

    const label = "EMAIL ADDRESS";
    const buttonText = "RESEND";
    const componentVisibility = this.isThisComponentInView()
      ? "visible"
      : "hidden";

    return (
      <div
        className="body-section activate-ui-body-section"
        style={{ visibility: componentVisibility }}
      >
        <form className="input-section" onSubmit={onSubmit} autoComplete="off">
          <Space height="16px" />
          <div className="header">Activate Your Account</div>
          <Space height="4px" />
          <div className="description">
            Please check your email for an activation link. Didn&#39;t receive
            an activation link? Use the form below to resend the email.
          </div>
          <Space height="19px" />

          <TextInput
            ref={this.textInputRef}
            value={activateEmailAddress}
            label={label}
            onChange={updateActivateEmailAddress}
            onClear={clearActivateEmailAddress}
            shouldFocusAfterTransitionDuration={true}
          />

          <Space height="28px" />
          <Button text={buttonText} buttonType="submit" />
        </form>
      </div>
    );
  }
}

export default CreateAccountFormActivate;
