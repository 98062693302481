// Copyright (C) 2019 Pepperdata Inc. - All rights reserved.
// @flow

import React from "react";
import LoginContainer from "../components/login-container";
import { getFullImgPath, isDemo, isLDAPEnabled } from "../utils";
import Space from "../ui-elements/space";
import Footer from "../components/footer";

const LoginPage = () => {
  const additionalClass = isLDAPEnabled() || isDemo() ? "ldap-login" : "";
  return (
    <div className={"login-page-container " + additionalClass}>
      <div className="pd-login-page">
        <img
          className="logo"
          src={getFullImgPath("pepperdata-logo.png")}
          alt="Pepperdata's Logo"
        />
        {window.loginInfo && window.loginInfo.preLoginMessage ? (
          <div className="pre-login-message">
            {window.loginInfo.preLoginMessage}
          </div>
        ) : null}
        <Space height={"22px"} />
        <LoginContainer />
      </div>
      <Footer />
    </div>
  );
};

export default LoginPage;
