// Copyright (C) 2018 Pepperdata Inc. - All rights reserved.
// @flow

import React from "react";
import { SLIDE_UI_TRANSITION_DURATION } from "../constants";
import { onEnterOrSpaceDownPropsBuilder } from "../utils";
import type { StrengthBarConfig } from "./strength-bar";
import StrengthBar from "./strength-bar";

type Props = {
  autoComplete?: string,
  id?: string, // used for accessibility when associating label to input
  label: string,
  className?: string,
  onChange: (SyntheticKeyboardEvent<HTMLInputElement>) => void,
  onClear: () => void,
  value: string,
  name?: string,
  // when type is 'text' the input will display values
  // when type is 'password' the input will hide values
  type: "text" | "password" | "email",
  description?: string,
  errorDescription?: string,
  strengthBarConfigs?: Array<StrengthBarConfig>,
  shouldFocusAfterTransitionDuration: boolean,
  disabled: boolean,
};

const defaultProps = {
  type: "text",
  shouldFocusAfterTransitionDuration: false,
  disabled: false,
};

class TextInput extends React.Component<Props, null> {
  static defaultProps = defaultProps;
  realInputField: { current: null | HTMLInputElement };
  hiddenInputField: { current: null | HTMLInputElement };

  constructor(props: Props) {
    super(props);

    this.realInputField = React.createRef();
    this.hiddenInputField = React.createRef();
  }

  actionClearValue = () => {
    // disable clear action icon when component is disabled
    const { disabled } = this.props;
    if (disabled === true) {
      return;
    }

    this.props.onClear();

    // keep focus on input field after clicking on action icon
    this.focusOnRealInput();
  };

  focusOnInput = () => {
    const { shouldFocusAfterTransitionDuration } = this.props;

    if (shouldFocusAfterTransitionDuration) {
      this.focusOnInputAfterTransitionDuration();
    } else {
      this.focusOnRealInput();
    }
  };

  // We delay the focus on the input field to prevent
  // a focus from happening in the middle of a css transition.
  // A focus on an element will immediately 'scroll the element into view'
  // which will cause unpredictable behaviour to the css transition as
  // it loses context of its original anchor point, resulting in an
  // undesireable destination spot.
  //
  // Therefore, we create a hidden input field that is always in
  // the browsers view with 'position fixed' and focus on the hidden
  // input field first, wait for the transition duration to finish,
  // then focus on the real input field.
  focusOnInputAfterTransitionDuration = () => {
    // Focus on hidden input first
    this.focusOnHiddenInput();

    // Wait for transition to finish
    setTimeout(() => {
      // Focus on real input after transition is over
      this.focusOnRealInput();
    }, SLIDE_UI_TRANSITION_DURATION + 200);
  };

  focusOnRealInput = () => {
    if (this.realInputField && this.realInputField.current) {
      this.realInputField.current.focus();
    }
  };

  focusOnHiddenInput = () => {
    if (this.hiddenInputField && this.hiddenInputField.current) {
      this.hiddenInputField.current.focus();
    }
  };

  // Note(Kevin): Prevent the hidden input field from using tab because
  // we want to disable the ability to change focus on an element during
  // a UI transition. If a focus were to happen, the UI might jitter/break
  // the transition resulting in an unexpected UI appearance.
  disableTabFunctionality = (event: SyntheticKeyboardEvent<>) => {
    const TAB_KEY_CODE = 9;
    if (event.key === "Tab" || event.keyCode === TAB_KEY_CODE) {
      event.preventDefault();
    }
  };

  render() {
    const {
      autoComplete = "",
      id = null,
      label,
      className = "",
      onChange,
      value,
      type,
      name = "",
      description = "",
      errorDescription,
      strengthBarConfigs,
      shouldFocusAfterTransitionDuration,
      disabled,
    } = this.props;

    const hasTextClassName = value && value.length > 0 ? "has-text" : "";
    const hasErrorClassName =
      typeof errorDescription === "string" && errorDescription.length > 0
        ? "has-error"
        : "";
    const potentialForError = typeof errorDescription === "string";
    const isDisabledClassName = disabled ? "is-disabled" : "";

    const classes = [
      hasTextClassName,
      hasErrorClassName,
      isDisabledClassName,
      className,
    ].join(" ");

    return (
      <div className={`pd-text-input ${classes}`}>
        <div className="input-container">
          {/* Hidden input field */}
          {shouldFocusAfterTransitionDuration && (
            <input
              autoComplete={autoComplete || "off"}
              tabIndex="-1" // Do not want the hidden input to be accessible
              className="hidden-input"
              ref={this.hiddenInputField}
              value={value}
              onChange={onChange}
              type={type}
              disabled={disabled}
              onKeyDown={this.disableTabFunctionality}
            />
          )}

          {/* Real input field */}
          <input
            autoComplete={autoComplete || "off"}
            className="real-input"
            ref={this.realInputField}
            value={value}
            onChange={onChange}
            id={id || name}
            name={name}
            type={type}
            disabled={disabled}
            aria-label={label}
          />

          {strengthBarConfigs && (
            <StrengthBar strengthBarConfigs={strengthBarConfigs} />
          )}
          {label && <label htmlFor={id}>{label}</label>}
          <div className="action-icon-container">
            <i
              className="action-icon material-icons"
              onClick={this.actionClearValue}
              {...onEnterOrSpaceDownPropsBuilder(this.actionClearValue)}
            >
              cancel
            </i>
          </div>
        </div>
        {description && <div className="input-description">{description}</div>}

        {/* Reserve white space below the input field for
        the error message to prevent DOM from adjusting. */}
        {potentialForError && (
          <div className="input-description input-error-description">
            {errorDescription}
          </div>
        )}
      </div>
    );
  }
}

export default TextInput;
