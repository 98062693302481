// Copyright (C) 2019 Pepperdata Inc. - All rights reserved.
// @flow

import React from "react";

type Props = {
  label?: string,
  checked: boolean,
  onClick: () => void,
  className?: string,
  hasError: boolean,
};

const defaultProps = {
  hasError: false,
};

type State = void;

class Checkbox extends React.Component<Props, State> {
  static defaultProps = defaultProps;

  constructor(props: Props) {
    super(props);
  }

  onKeyDown = (event: SyntheticKeyboardEvent<HTMLSpanElement>) => {
    if (event.key === "Enter" || event.key === " ") {
      this.props.onClick();
    }
  };

  render() {
    const { onClick, className = "", checked, label, hasError } = this.props;

    const hasErrorClassName = hasError ? "has-error" : "";

    return (
      <div
        className={`pd-checkbox ${className} ${hasErrorClassName}`}
        role={"checkbox"}
        onClick={onClick}
      >
        <input type="checkbox" checked={checked} readOnly />
        <span
          className="fake-checkbox icon-base"
          tabIndex="0"
          onKeyDown={this.onKeyDown}
        />
        {label && <label>{label}</label>}
      </div>
    );
  }
}

export default Checkbox;
