// Copyright (C) 2019 Pepperdata Inc. - All rights reserved.
// @flow

import React from "react";
import Space from "../ui-elements/space";

type Props = {
  visibility: string,
};

const defaultProps = {
  visibility: "visible",
};

// This component text is used twice, once for this form and
// another time in the VerifyAccountStandaloneForm component.
const VerifyAccountDescriptionBodySection = (props: Props) => {
  const { visibility } = props;

  return (
    <div className="body-section" style={{ visibility }}>
      <div className="input-section">
        <Space height="40px" />
        <div className="header">Almost done!</div>
        <div className="description">
          <p>
            We just sent you a verification email. Before you can log in to your
            account, you need to activate it by clicking the link in the
            verification email. Please check your email inbox to continue.
          </p>
          <p>
            If you think you&apos;ve reached this page by mistake, you can{" "}
            <a href="/logout">log out</a> and try again to log in.
          </p>
        </div>
        <Space height="20px" />
      </div>
    </div>
  );
};

VerifyAccountDescriptionBodySection.defaultProps = defaultProps;

export default VerifyAccountDescriptionBodySection;
