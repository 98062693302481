// Copyright (C) 2019 Pepperdata Inc. - All rights reserved.
// @flow

import React from "react";

type Props = {
  width: string | number,
  height: string | number,
};

const defaultProps = {
  width: "100%",
  height: "100%",
};

// The Space component is useful for specific spacing only used in a component once.
// Motivation is to prevent cluttering DOM elements with classNames
// and the SCSS files with heights/widths/paddings/margins.
const Space = (props: Props) => {
  const { width, height } = props;
  const style = {
    width,
    height,
  };

  return <div className="pd-space" style={style} />;
};

Space.defaultProps = defaultProps;

export default Space;
