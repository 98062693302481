import React from "react";
import ReactDOM from "react-dom";
import App from "./app";

import "../styles/style.scss";

// use polyfills for IE 11
import "whatwg-fetch"; // for window.fetch
import "promise-polyfill/src/polyfill"; // for Promise

const Login = () => {
  return <App />;
};

ReactDOM.render(<Login />, document.getElementById("pd-login"));
