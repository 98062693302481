// Copyright (C) 2019 Pepperdata Inc. - All rights reserved.
// @flow

import _ from "lodash";
import React from "react";
import { CONTAINER_CREATE, CREATE_FORM_EMAIL } from "../constants";
import Button from "../ui-elements/button";
import Checkbox from "../ui-elements/checkbox";
import Space from "../ui-elements/space";
import TextInput from "../ui-elements/text-input";
import { getEmailAddressValidationMessage } from "../utils";

type Props = {
  onSubmit: (SyntheticEvent<HTMLFormElement>) => Promise<any> | void,
  updateEmailAddress: (SyntheticKeyboardEvent<HTMLInputElement>) => void,
  clearEmailAddress: () => void,
  emailAddress: string,
  containerFlow: string,
  uiFlow: string,
  emailErrorMessage: string,
  isLoading: boolean,
};

type State = {
  validatorResults: {
    emailAddressErrorDescription: string,
  },
  isTermsAgreed: boolean,
  showCheckboxError: boolean,
};

class CreateAccountFormEmail extends React.Component<Props, State> {
  isEmailAddressTouched: boolean;
  runValidatorsTimeoutId: ?TimeoutID;
  textInputRef: { current: null | React$ElementRef<typeof TextInput> };

  constructor(props: Props) {
    super(props);

    this.state = {
      validatorResults: {
        emailAddressErrorDescription: "",
      },
      isTermsAgreed: false,
      showCheckboxError: false,
    };

    this.isEmailAddressTouched = false;
    this.runValidatorsTimeoutId = null;
    this.textInputRef = React.createRef();
  }

  hasErrors = () => {
    const { isTermsAgreed, validatorResults } = this.state;
    const { emailAddress, emailErrorMessage } = this.props;
    const { emailAddressErrorDescription } = validatorResults;

    if (
      isTermsAgreed === false ||
      emailAddress.length === 0 ||
      emailAddressErrorDescription.length > 0 ||
      emailErrorMessage.length > 0
    ) {
      return true;
    }
    return false;
  };

  onSubmit = (event: SyntheticEvent<HTMLFormElement>) => {
    event.preventDefault();
    const parentOnSubmit = this.props.onSubmit;
    const { isTermsAgreed } = this.state;

    // If checkbox is not checked during onSubmit then
    // we display checkbox's border as red to bring attention to the user
    if (isTermsAgreed === false) {
      this.setState({ showCheckboxError: true });
    }

    // Force run validators by setting inputs to be touched
    this.isEmailAddressTouched = true;

    this.runValidators(() => {
      // only run parent's onSubmit function if there are no validation errors
      if (this.hasErrors() === false) {
        parentOnSubmit(event);
      }
    });
  };

  updateEmailAddress = (event: SyntheticKeyboardEvent<HTMLInputElement>) => {
    const parentUpdateEmailAddress = this.props.updateEmailAddress;
    this.isEmailAddressTouched = true;
    parentUpdateEmailAddress(event);
  };

  termsCheckboxOnClick = () => {
    const nextValue = !this.state.isTermsAgreed;
    this.setState({ isTermsAgreed: nextValue, showCheckboxError: false });
  };

  uncheckTermsCheckbox = () => {
    this.setState({ isTermsAgreed: false });
  };

  validatorEmail = (forceCheck: boolean = false) => {
    const { emailAddress } = this.props;

    if (forceCheck === false && this.isEmailAddressTouched === false) {
      return {};
    }

    // error when email address is empty or invalid
    const emailAddressValidationMsg =
      getEmailAddressValidationMessage(emailAddress);
    if (emailAddressValidationMsg.length > 0) {
      return {
        emailAddressErrorDescription: emailAddressValidationMsg,
      };
    }

    return {};
  };

  resetValidatorErrors = () => {
    this.setState({
      validatorResults: {
        emailAddressErrorDescription: "",
      },
    });
  };

  // callback (function): Place any code that depends on the
  // validation errors in the callback function so that it will
  // run after the errors has been set to state. Reason for the
  // callback is because setState is asynchronous.
  runValidators = (callback?: () => void) => {
    const validatorResult = this.validatorEmail();
    const hasError = Object.keys(validatorResult).length > 0;

    if (hasError) {
      const newValidatorResults = {
        ...this.state.validatorResults,
        ...validatorResult,
      };
      if (callback !== undefined) {
        this.setState({ validatorResults: newValidatorResults }, callback);
      } else {
        this.setState({ validatorResults: newValidatorResults });
      }
    } else {
      if (callback !== undefined) {
        callback();
      }
    }
  };

  runValidatorsWithDelay = () => {
    this.resetValidatorErrors();

    // Prevent execution of past timeout
    if (this.runValidatorsTimeoutId !== null) {
      clearTimeout(this.runValidatorsTimeoutId);
    }

    // Run validators after a user stops typing by giving
    // them 500ms delay between each key stroke
    this.runValidatorsTimeoutId = setTimeout(() => {
      this.runValidators();
    }, 500);
  };

  focusOnInput = () => {
    if (this.textInputRef.current !== null) {
      this.textInputRef.current.focusOnInput();
    }
  };

  isThisComponentInView() {
    const { containerFlow, uiFlow } = this.props;
    return containerFlow === CONTAINER_CREATE && uiFlow === CREATE_FORM_EMAIL;
  }

  componentDidUpdate(prevProps: Props) {
    const prevEmailAddress = prevProps.emailAddress;
    const emailAddress = this.props.emailAddress;

    if (prevEmailAddress !== emailAddress) {
      this.runValidatorsWithDelay();
    }

    // when this UI is being viewed, focus on the input field
    const { containerFlow, uiFlow } = this.props;
    if (
      this.isThisComponentInView() &&
      (prevProps.containerFlow !== containerFlow || prevProps.uiFlow !== uiFlow)
    ) {
      this.focusOnInput();

      // uncheck the terms checkbox for reconfirmation
      this.uncheckTermsCheckbox();
    }
  }

  render() {
    const { isTermsAgreed, validatorResults, showCheckboxError } = this.state;
    const { emailAddress, clearEmailAddress, emailErrorMessage, isLoading } =
      this.props;
    const label = "EMAIL ADDRESS";
    const buttonText = "NEXT";
    const termsTextCopy = "I accept the ";
    const termsConditionsTextCopy = "Terms and Conditions";
    const termsConditionsLink =
      "https://www.pepperdata.com/legal/privacy-policy";
    const componentVisibility = this.isThisComponentInView()
      ? "visible"
      : "hidden";

    const emailAddressErrorDescription =
      emailErrorMessage || validatorResults.emailAddressErrorDescription;

    // Disable submit button when...
    // input is empty, input is an invalid email address or when terms is not checked.
    const shouldDisableSubmitButton =
      emailAddress.length === 0 ||
      _.keys(this.validatorEmail(true)).length > 0 ||
      isTermsAgreed === false;

    return (
      <div className="body-section" style={{ visibility: componentVisibility }}>
        <form
          className="input-section"
          onSubmit={this.onSubmit}
          autoComplete="off"
        >
          <Space height="28px" />
          <TextInput
            ref={this.textInputRef}
            value={emailAddress}
            label={label}
            onChange={this.updateEmailAddress}
            onClear={clearEmailAddress}
            errorDescription={emailAddressErrorDescription}
            shouldFocusAfterTransitionDuration={true}
            disabled={isLoading}
          />

          <Space height="20px" />

          <div className="terms-container">
            <Checkbox
              className="terms-checkbox"
              checked={isTermsAgreed}
              onClick={this.termsCheckboxOnClick}
              hasError={showCheckboxError}
            />
            <div>{termsTextCopy}</div>
            <a
              href={termsConditionsLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              {termsConditionsTextCopy}
            </a>
          </div>

          <Space height="15px" />

          <Button
            text={buttonText}
            buttonType="submit"
            isLoading={isLoading}
            disabled={shouldDisableSubmitButton}
          />
        </form>
      </div>
    );
  }
}

export default CreateAccountFormEmail;
