// Copyright (C) 2018 Pepperdata Inc. - All rights reserved.
// @flow

import { REGEX_EMAIL_FORMAT } from "./constants";

export const getFullImgPath = (filename: string): string => {
  return `/resources/images/${filename}`;
};

export const getFirstPathnameFromUrl = () => {
  let pathname = window.location.pathname;
  // match the testpage has we use for playwright to simulate path handling
  const match = window.location.hash.match(/testpage=(.*)/);
  if (match) {
    pathname = match[1];
  }

  // Split string based on '/'
  // Example: '/forgot' => ['', 'forgot']
  const splitPathname = pathname.split("/");

  // return first path
  return splitPathname[1];
};

/**
 * Return an array representing the path name
 *
 * Example: '/api/auth/samlexchange' => ['api', 'auth', 'samlexchange']
 */
export const getPathArrayFromUrl = () => {
  const pathname = window.location.pathname;

  // Split string based on '/'
  let splitPathname = pathname.split("/");

  // Remove any empty strings
  splitPathname = splitPathname.filter((subpath) => subpath !== "");

  return splitPathname;
};

export const replaceUrlPathname = (newPathname: string) => {
  // Replace url without appending a new object to history.
  // Be sure to preserve all query strings and hash
  const newPathnameWithSearchHash = decodeSafeParams(
    newPathname + window.location.search + window.location.hash
  );
  window.history.replaceState({}, document.title, newPathnameWithSearchHash);
};

export const removeHashFromUrl = () => {
  // Replace url without appending a new object to history.
  // Be sure to preserve all query strings and pathname while removing hash
  const newUrl = window.location.href.split("#")[0];
  window.history.replaceState({}, document.title, newUrl);
};

// Return an error message when given an invalid email address,
// otherwise, return an empty string.
export const getEmailAddressValidationMessage = (
  emailAddress: string
): string => {
  if (emailAddress.length === 0) {
    return "Enter an email address.";
  }

  if (REGEX_EMAIL_FORMAT.test(emailAddress) === false) {
    return "Enter a valid email address.";
  }

  return "";
};

// Return an error message when given an empty password,
// otherwise, return an empty string.
export const getPasswordValidationMessage = (password: string): string => {
  if (password.length === 0) {
    return "Enter your password.";
  }

  return "";
};

// Helper function used in tests.
// This functions accepts Any and returns Any so we can set
// `mockImplementation` without getting any flow errors.
// https://stackoverflow.com/a/45640537
export const mockAny = (mockFn: any) => mockFn;

// Decode symbols required for the browser to detect
// queryString (window.location.search) and
// hash (window.location.hash)
const decodeSafeParams = (queryString) => {
  return queryString
    .replace(/%2F/g, "/")
    .replace(/%3F/g, "?")
    .replace(/%3D/g, "=")
    .replace(/%23/g, "#");
};

/**
 * Parse querystring args into a key:value map
 * @param query
 * @returns {Object} a map of key:value
 *
 * Forked from dashboard/src/main/webapp/resources/scripts/string-utils.js
 */
export const parseQueryString = (query: string): Object => {
  if (!query) {
    return {};
  }
  // trim the leading # or ?
  if (query.indexOf("#") === 0 || query.indexOf("?") === 0) {
    query = query.substr(1);
  }
  const result = {};
  const vars = query.split("&");
  for (let i = 0; i < vars.length; i++) {
    // split only at first "="
    const separatorIndex = vars[i].indexOf("=");
    const pair = [
      vars[i].slice(0, separatorIndex),
      vars[i].slice(separatorIndex + 1),
    ];

    result[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1]);
  }
  // remove the empty mapping if it exists (can get this if there is no querystring)
  delete result[""];
  return result;
};

/**
 * Helper function used in tests.
 * Hide console error messages when we expect them so
 * it does not clutter the test output.
 */
export const hideConsoleErrorMsgs = () => {
  /* eslint-disable */
  global.console.errorPlaceholder = global.console.error;
  global.console.error = jest.fn();
  /* eslint-enable */
};

/**
 * Helper function used in tests.
 * Restore functionality of printing out error messages to console.
 */
export const restoreConsoleErrorMsgs = () => {
  /* eslint-disable */
  global.console.error.mockRestore();
  global.console.error = global.console.errorPlaceholder;
  /* eslint-enable */
};

/**
 * Function to add onEnterDown and onSpaceDown functionality to an element.
 * The function returns an object of properties which can then be spread into
 * a JSX element.
 *
 * @param actionToDo: function you want to execete after Enter
 *                    or Space has been pressed.
 */
export const onEnterOrSpaceDownPropsBuilder = (actionToDo: () => void) => {
  const onKeyDownHandler = (event: SyntheticKeyboardEvent<any>, actionToDo) => {
    if (event.key === "Enter" || event.key === " ") {
      event.preventDefault();
      actionToDo();
    }
  };

  return {
    tabIndex: "0",
    onKeyDown: (event: SyntheticKeyboardEvent<any>) =>
      onKeyDownHandler(event, actionToDo),
  };
};

export function isLDAPEnabled() {
  return window.loginInfo.authType === "PEPPERDATA_JWT_LDAP";
}

export function isDemo() {
  return window.loginInfo.authType === "DEMO";
}

export function canCreateNewAccount() {
  return !isDemo() && !isLDAPEnabled();
}
