// Copyright (C) 2018 Pepperdata Inc. - All rights reserved.
// @flow

// use the 'pd' prefix when constants are used as classNames to avoid any global name collisions
export const LOGIN_FORM_EMAIL = "pd-login-form-email";
export const LOGIN_FORM_PASSWORD = "pd-login-form-password";
export const LOGIN_FORM_FORGET_PASSWORD = "pd-login-form-forget-password";
export const CONTAINER_LOGIN = "pd-container-show-login";
export const CONTAINER_CREATE = "pd-container-show-create";
export const CONTAINER_CHANGE_PASSWORD_STANDALONE =
  "pd-container-change-password-standalone";
export const CONTAINER_VERIFY_STANDALONE = "pd-container-verify-standalone";
export const CONTAINER_WELCOME_STANDALONE = "pd-container-welcome-standalone";
export const CONTAINER_SSO_IN_PROGRESS_STANDALONE =
  "pd-container-sso-in-progress-standalone";
export const CREATE_FORM_EMAIL = "pd-create-form-email";
export const CREATE_FORM_NAMES = "pd-create-form-names";
export const CREATE_FORM_PASSWORD = "pd-create-form-password";
export const CREATE_FORM_VERIFY = "pd-create-form-verify";
export const CREATE_FORM_ACTIVATE = "pd-create-form-activate";
export const CREATE_FORM_SSO_LOGIN = "pd-create-form-sso-login";

// path name associated with UIs
export const LOGIN_FORM_LOGIN_PATH = "login";
export const LOGIN_FORM_FORGET_PATH = "forgot";
export const CHANGE_PASSWORD_STANDALONE_PATH = "change";
export const VERIFY_STANDALONE_PATH = "verify";
export const WELCOME_STANDALONE_PATH = "welcome";
export const API_PATH = "api";
export const SSO_IN_PROGERSS_PATH_ARRAY = ["api", "auth", "samlexchange"];

// colors used in strength config
export const COLOR_RED = "#b33b3b";
export const COLOR_ORANGE = "#c17225";
export const COLOR_YELLOW = "#c5aa28";
export const COLOR_GREEN = "#7b902c";

// Slide transition duration between UI
// Same value is shared with scss (slide-ui-transition-duration)
export const SLIDE_UI_TRANSITION_DURATION = 300; //ms

// Email format pattern <anything>@<anything>.<anything>
export const REGEX_EMAIL_FORMAT = /[^@]+@[^@]+\.[^@]+/;

export const RESET_PASSWORD_TOKEN_KEY = "reset-token";

// constants used for testing
const REGEX_ONE_UPPER_ONE_NUMBER = "^(?=.*\\d)(?=.*[A-Z]).{8,}$";
const TEXT_ONE_UPPER_ONE_NUMBER = "8 character minimum, 1 uppercase, 1 digit";
export const mockPasswordRequirements = {
  regex: REGEX_ONE_UPPER_ONE_NUMBER,
  message: TEXT_ONE_UPPER_ONE_NUMBER,
};
export const DEFAULT_PASSWORD_REQUIREMENT_MSG =
  "Requirements: 8 character minimum";
export const SERVER_DUPLICATE_ACCOUNT_ERROR_MSG = "User already exists";

// Front facing error messages
/* eslint-disable */
export const DEFAULT_ERROR_MSG =
  "An error has occured. Please try again later.";
export const DUPLICATE_ACCOUNT_ERROR_MSG =
  "That email address is already taken. Please try another.";
export const EMAIL_PW_REQUIREMENTS_ERROR_MSG =
  "Error trying to validate email address. Please try again later.";
export const ACCOUNT_CREATION_ERROR_MSG =
  "Error trying to create account. Please try again later.";
export const TOKEN_PW_REQUIREMENTS_ERROR_MSG =
  "Error trying to validate reset token. Please request a new one.";
/* eslint-enable */

// Shared messages between login form and create form
export const LOGIN_FORM_ACTION = "LOG IN";
export const LOGIN_FORM_SENTENCE = "with your account";
export const CREATE_FORM_ACTION = "NEW USER?";
export const CREATE_FORM_SENTENCE = "Create Account";

export const VALIDATION_DEBOUNCE_MS = 500;

// NOTE(Kevin): Direct users to create zendesk support
// tickets instead of emailing support@pepperdata.com
export const PEPPERDATA_SUPPORT_URL =
  "https://pepperdata.zendesk.com/hc/en-us/requests/new";
