// Copyright (C) 2019 Pepperdata Inc. - All rights reserved.
// @flow

import React from "react";
import { CONTAINER_CREATE, CREATE_FORM_VERIFY } from "../constants";
import VerifyAccountDescriptionBodySection from "./create-account-form-verify-body";

type Props = {
  containerFlow: string,
  uiFlow: string,
};

const CreateAccountFormVerify = (props: Props) => {
  const { containerFlow, uiFlow } = props;
  const isThisComponentInView =
    containerFlow === CONTAINER_CREATE && uiFlow === CREATE_FORM_VERIFY;
  const componentVisibility = isThisComponentInView ? "visible" : "hidden";

  return (
    <VerifyAccountDescriptionBodySection visibility={componentVisibility} />
  );
};

export default CreateAccountFormVerify;
