// Copyright (C) 2018 Pepperdata Inc. - All rights reserved.
// @flow

import React from "react";
import { getFullImgPath } from "../utils";

type Props = {
  width?: string,
  height: string,
};

const defaultProps = {
  height: "100px",
};

const PdLoadingIndicator = (props: Props) => {
  return (
    <div className="pd-loading-indicator">
      <img
        width={props.width}
        height={props.width ? "auto" : props.height}
        src={getFullImgPath("pepper_loading.gif")}
        alt="Loading"
      />
    </div>
  );
};

PdLoadingIndicator.defaultProps = defaultProps;

export default PdLoadingIndicator;
