// Copyright (C) 2019 Pepperdata Inc. - All rights reserved.
// @flow

import React from "react";
import VerifyAccountDescriptionBodySection from "./create-account-form-verify-body";

const VerifyAccountStandaloneForm = () => {
  return (
    <div className="pd-standalone-form">
      {/* Top Section */}
      <div className="top-section">
        <div>
          <span className="action">VERIFICATION</span>{" "}
          <span>for your account</span>
        </div>
      </div>

      {/* Body Section */}
      <VerifyAccountDescriptionBodySection />
    </div>
  );
};

export default VerifyAccountStandaloneForm;
