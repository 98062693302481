// Copyright (C) 2019 Pepperdata Inc. - All rights reserved.
// @flow

import React, { Fragment } from "react";
import TextInput from "../ui-elements/text-input";
import Space from "../ui-elements/space";
import BodySectionPasswordView from "./login-form-body-section-password-view";
import type { Props } from "./login-form-body-section-password";
import { onEnterOrSpaceDownPropsBuilder } from "../utils";
import LoginFormBodySectionSsoLogin from "./login-form-body-section-sso-login";

type AdditionalProps = {
  isLoading: boolean,
  onSubmitPassword: (SyntheticEvent<HTMLFormElement>) => Promise<any>,
  onSubmitSsoLogin: (SyntheticEvent<HTMLFormElement>) => void,
  textInputRef: { current: null | React$ElementRef<typeof TextInput> },
  passwordInputErrorDescription: string,
  disabled: boolean,
  isPasswordAllowed: boolean,
};
type CombinedProps = Props & AdditionalProps;

type SsoState = {
  displayPasswordForSsoSupportedUi: boolean,
};

class BodySectionPasswordSsoSupported extends React.Component<
  CombinedProps,
  SsoState,
> {
  ssoSubmitButtonRef: { current: null | React$ElementRef<any> };

  constructor() {
    super();

    this.state = {
      displayPasswordForSsoSupportedUi: false,
    };
    this.ssoSubmitButtonRef = React.createRef();
  }

  showPasswordForSsoSupportedUi = (event: SyntheticEvent<>) => {
    event.preventDefault();
    this.setState({ displayPasswordForSsoSupportedUi: true });
  };

  onKeyDownShowPasswordForSsoSupportedUi = () => {
    this.setState({ displayPasswordForSsoSupportedUi: true });
  };

  focusOnButton = () => {
    if (this.ssoSubmitButtonRef.current) {
      // Delay the focus slightly to wait until the
      // UI slide transition has completed first. If
      // we do not wait, we will lose focus during the
      // UI transition.
      this.ssoSubmitButtonRef.current.focusOnButtonWithDelay();
    }
  };

  render() {
    const { onSubmitSsoLogin, isPasswordAllowed } = this.props;

    const { displayPasswordForSsoSupportedUi } = this.state;

    return (
      <Fragment>
        <Space height={displayPasswordForSsoSupportedUi ? "10px" : "75px"} />
        <LoginFormBodySectionSsoLogin onSubmit={onSubmitSsoLogin} ref={this.ssoSubmitButtonRef} />

        {/* If the domain does not allow SSO with password, hide the components below */}
        {isPasswordAllowed && (
          <React.Fragment>
            <Space height={displayPasswordForSsoSupportedUi ? "3px" : "10px"} />
            <div className="or-divider">
              <span />
              or
              <span />
            </div>
            <Space height={displayPasswordForSsoSupportedUi ? 0 : "5px"} />

            {displayPasswordForSsoSupportedUi ? (
              //$FlowFixMe
              <BodySectionPasswordView
                trimVerticalWhiteSpace={true}
                {...this.props}
              />
            ) : (
              <a
                onClick={this.showPasswordForSsoSupportedUi}
                className="show-password-input"
                {...onEnterOrSpaceDownPropsBuilder(
                  this.onKeyDownShowPasswordForSsoSupportedUi
                )}
              >
                Sign in with your Pepperdata account password.
              </a>
            )}
          </React.Fragment>
        )}
      </Fragment>
    );
  }
}

export default BodySectionPasswordSsoSupported;
