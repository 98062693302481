// Copyright (C) 2018 Pepperdata Inc. - All rights reserved.

import React, { useState, useEffect, Fragment } from "react";
import _ from "lodash";
import LoginForm from "./login-form";
import CreateAccountForm from "./create-account-form";
import {
  CONTAINER_LOGIN,
  CONTAINER_CREATE,
  CONTAINER_CHANGE_PASSWORD_STANDALONE,
  LOGIN_FORM_EMAIL,
  LOGIN_FORM_PASSWORD,
  LOGIN_FORM_FORGET_PASSWORD,
  CREATE_FORM_EMAIL,
  CREATE_FORM_PASSWORD,
  CREATE_FORM_VERIFY,
  CREATE_FORM_ACTIVATE,
  LOGIN_FORM_FORGET_PATH,
  LOGIN_FORM_LOGIN_PATH,
  CHANGE_PASSWORD_STANDALONE_PATH,
  CONTAINER_VERIFY_STANDALONE,
  VERIFY_STANDALONE_PATH,
  CONTAINER_WELCOME_STANDALONE,
  WELCOME_STANDALONE_PATH,
  SSO_IN_PROGERSS_PATH_ARRAY,
  CONTAINER_SSO_IN_PROGRESS_STANDALONE,
  API_PATH,
  CREATE_FORM_NAMES,
  CREATE_FORM_SSO_LOGIN,
} from "../constants";
import {
  getFirstPathnameFromUrl,
  replaceUrlPathname,
  removeHashFromUrl,
  getPathArrayFromUrl,
} from "../utils";
import ChangePasswordStandaloneForm from "./change-password-standalone-form";
import VerifyAccountStandaloneForm from "./verify-account-standalone-form";
import WelcomeStandaloneForm from "./welcome-standalone-form";
import SsoInProgressStandaloneForm from "./sso-in-progress-standalone-form";

const LoginContainer = () => {
  const [containerFlow, setContainerFlow] = useState(CONTAINER_LOGIN);
  const [loginFormUiFlow, setLoginFormUiFlow] = useState(LOGIN_FORM_EMAIL);
  const [createAccountFormUiFlow, setCreateAccountFormUiFlow] =
    useState(CREATE_FORM_EMAIL);
  const [isEmailValidated, setIsEmailValidated] = useState(false);

  const switchToCreate = () => {
    if (containerFlow !== CONTAINER_CREATE) {
      setContainerFlow(CONTAINER_CREATE);
    }
    if (createAccountFormUiFlow !== CREATE_FORM_EMAIL) {
      setCreateAccountFormUiFlow(CREATE_FORM_EMAIL);
    }

    replaceUrlToDefaultPathname();
  };

  const switchToLogin = () => {
    if (containerFlow !== CONTAINER_LOGIN) {
      setContainerFlow(CONTAINER_LOGIN);
    }
    if (loginFormUiFlow !== LOGIN_FORM_EMAIL) {
      setLoginFormUiFlow(LOGIN_FORM_EMAIL);
    }

    replaceUrlToDefaultPathname();
  };

  const switchToChangePasswordStandalone = () => {
    if (containerFlow !== CONTAINER_CHANGE_PASSWORD_STANDALONE) {
      setContainerFlow(CONTAINER_CHANGE_PASSWORD_STANDALONE);
    }
  };

  const switchToVerifyStandalone = () => {
    if (containerFlow !== CONTAINER_VERIFY_STANDALONE) {
      setContainerFlow(CONTAINER_VERIFY_STANDALONE);
    }
    replaceUrlPathname(`/${VERIFY_STANDALONE_PATH}`);
  };

  const switchToWelcomeStandalone = () => {
    if (containerFlow !== CONTAINER_WELCOME_STANDALONE) {
      setContainerFlow(CONTAINER_WELCOME_STANDALONE);
    }
    replaceUrlPathname(`/${WELCOME_STANDALONE_PATH}`);
  };

  const switchToSsoInProgressStandalone = () => {
    if (containerFlow !== CONTAINER_SSO_IN_PROGRESS_STANDALONE) {
      setContainerFlow(CONTAINER_SSO_IN_PROGRESS_STANDALONE);
    }
    replaceUrlPathname(`/${SSO_IN_PROGERSS_PATH_ARRAY.join("/")}`);
  };

  const goToUiForgetPassword = () => {
    switchToLogin();
    setLoginFormUiFlow(LOGIN_FORM_FORGET_PASSWORD);
    replaceUrlPathname(`/${LOGIN_FORM_FORGET_PATH}`);
  };

  const goToUiCreateEmail = () => {
    switchToCreate();
    setCreateAccountFormUiFlow(CREATE_FORM_EMAIL);
    replaceUrlToDefaultPathname();
  };

  const goToUiCreateNames = () => {
    switchToCreate();
    setCreateAccountFormUiFlow(CREATE_FORM_NAMES);
    replaceUrlToDefaultPathname();
  };

  const goToUiCreatePassword = () => {
    switchToCreate();
    setCreateAccountFormUiFlow(CREATE_FORM_PASSWORD);
    replaceUrlToDefaultPathname();
  };

  const goToUiCreateVerify = () => {
    switchToCreate();
    setCreateAccountFormUiFlow(CREATE_FORM_VERIFY);
    replaceUrlToDefaultPathname();
  };

  const goToUiCreateActivate = () => {
    switchToCreate();
    setCreateAccountFormUiFlow(CREATE_FORM_ACTIVATE);
    replaceUrlToDefaultPathname();
  };

  const goToSSOLogin = () => {
    switchToCreate();
    setCreateAccountFormUiFlow(CREATE_FORM_SSO_LOGIN);
    replaceUrlToDefaultPathname();
  };

  const replaceUrlToDefaultPathname = () => {
    replaceUrlPathname(`/${LOGIN_FORM_LOGIN_PATH}`);
  };

  const goToUiLoginEmail = () => {
    switchToLogin();
    setLoginFormUiFlow(LOGIN_FORM_EMAIL);
    replaceUrlToDefaultPathname();
  };

  const goToUiLoginPassword = () => {
    switchToLogin();
    setLoginFormUiFlow(LOGIN_FORM_PASSWORD);
    replaceUrlToDefaultPathname();
  };

  const goToAppropiateUiOnLoad = () => {
    const pathname = getFirstPathnameFromUrl();

    switch (pathname) {
      case LOGIN_FORM_FORGET_PATH:
        goToUiForgetPassword();
        break;
      case CHANGE_PASSWORD_STANDALONE_PATH:
        switchToChangePasswordStandalone();
        break;
      case VERIFY_STANDALONE_PATH:
        switchToVerifyStandalone();
        break;
      case WELCOME_STANDALONE_PATH:
        switchToWelcomeStandalone();
        break;
      case API_PATH:
        const pathArray = getPathArrayFromUrl();
        if (_.isEqual(pathArray, SSO_IN_PROGERSS_PATH_ARRAY)) {
          switchToSsoInProgressStandalone();
        }
        break;
      default:
        break;
    }
  };

  const setEmailAsValidated = () => {
    setIsEmailValidated(true);
    removeHashFromUrl();
  };

  const handleHashValue = () => {
    if (window.location.href.match("#")) {
      const hashValue = window.location.hash;

      if (hashValue === "#valid-email=true") {
        setEmailAsValidated();
      } else if (hashValue.match(/id_token=(.*?)(&|$)/)) {
        window.location.href =
          "/api/auth/exchange?" + window.location.hash.slice(1);
      }
    }
  };

  useEffect(() => {
    handleHashValue();
    goToAppropiateUiOnLoad();
  }, [window.location.hash]);

  // Only render necessary components based on containerFlow
  let componentsToRender;

  if (containerFlow === CONTAINER_CHANGE_PASSWORD_STANDALONE) {
    // render Change Password UI
    componentsToRender = <ChangePasswordStandaloneForm />;
  } else if (containerFlow === CONTAINER_VERIFY_STANDALONE) {
    // render Verify Standalone UI
    componentsToRender = <VerifyAccountStandaloneForm />;
  } else if (containerFlow === CONTAINER_WELCOME_STANDALONE) {
    // render Welcome Standalone UI
    componentsToRender = <WelcomeStandaloneForm />;
  } else if (containerFlow === CONTAINER_SSO_IN_PROGRESS_STANDALONE) {
    // render SSO In Progress Standalone UI
    componentsToRender = <SsoInProgressStandaloneForm />;
  } else {
    // default: render Login/Create UI
    componentsToRender = (
      <Fragment>
        <LoginForm
          containerFlow={containerFlow}
          uiFlow={loginFormUiFlow}
          switchToCreate={switchToCreate}
          goToUiLoginEmail={goToUiLoginEmail}
          goToUiLoginPassword={goToUiLoginPassword}
          goToUiForgetPassword={goToUiForgetPassword}
        />
        <CreateAccountForm
          containerFlow={containerFlow}
          uiFlow={createAccountFormUiFlow}
          switchToLogin={switchToLogin}
          goToUiCreateEmail={goToUiCreateEmail}
          goToUiCreateNames={goToUiCreateNames}
          goToUiCreatePassword={goToUiCreatePassword}
          goToUiCreateVerify={goToUiCreateVerify}
          goToUiCreateActivate={goToUiCreateActivate}
          goToSSOLogin={goToSSOLogin}
        />
      </Fragment>
    );
  }

  const emailValidatedMessageClassName = isEmailValidated ? "show" : "hidden";
  const emailValidatedMessageToRender = (
    <div
      className={`pd-email-verified-message ${emailValidatedMessageClassName}`}
    >
      <i className="material-icons">check</i>
      You have successfully verified your account. Please log in to continue.
    </div>
  );

  return (
    <Fragment>
      {emailValidatedMessageToRender}
      <div className={`pd-login-container pd-drop-shadow ${containerFlow}`}>
        {componentsToRender}
      </div>
    </Fragment>
  );
};

export default LoginContainer;
