// Copyright (C) 2019 Pepperdata Inc. - All rights reserved.
// @flow

import _ from "lodash";
import React, { Fragment } from "react";
import { ssoLogin } from "../auth/auth-helpers";
import {
  CONTAINER_LOGIN,
  LOGIN_FORM_PASSWORD,
  VALIDATION_DEBOUNCE_MS,
} from "../constants";
import Space from "../ui-elements/space";
import TextInput from "../ui-elements/text-input";
import { getPasswordValidationMessage } from "../utils";
import GoBackHeader from "./go-back-header";
import BodySectionPasswordSsoSupported from "./login-form-body-section-password-sso";
import BodySectionPasswordView from "./login-form-body-section-password-view";

export type Props = {
  onSubmit: () => Promise<any>,
  updatePassword: (SyntheticKeyboardEvent<HTMLInputElement>) => void,
  clearPassword: (shouldClearErrorMessage: ?boolean) => void,
  emailAddress: string,
  password: string,
  forgetPasswordOnClick: () => void,
  authErrorMessage: string,
  uiFlow: string,
  containerFlow: string,
  backButtonOnClick: () => void,
  setBottomSectionDisability: (boolean) => void,
};

type State = {
  isLoading: boolean,
  validatorResults: {
    passwordErrorDescription: string,
  },
  lastSSOUserNameCheck: string | null,
  isSsoSupported: false,
  // is sso password allowed
  isPasswordAllowed: false
};

class BodySectionPassword extends React.Component<Props, State> {
  textInputRef: { current: null | React$ElementRef<typeof TextInput> };
  ssoBodySectionRef: { current: null | React$ElementRef<any> };

  constructor(props: Props) {
    super(props);

    this.textInputRef = React.createRef();
    this.ssoBodySectionRef = React.createRef();
    this.state = {
      isLoading: false,
      isSsoSupported: false,
      isPasswordAllowed: false,
      validatorResults: {
        passwordErrorDescription: "",
      },
      lastSSOUserNameCheck: null,
    };
  }

  focusOnInput = () => {
    if (this.textInputRef.current !== null) {
      this.textInputRef.current.focusOnInput();
    }
  };

  focusOnSsoButton = () => {
    if (this.ssoBodySectionRef.current) {
      this.ssoBodySectionRef.current.focusOnButton();
    }
  };

  setStateForUiBeginFetch = () => {
    this.setState({ isLoading: true });
    this.props.setBottomSectionDisability(true);
  };

  setStateForUiEndFetch = () => {
    this.setState({ isLoading: false });
    this.props.setBottomSectionDisability(false);
  };

  checkForValidationErrors = (password: string) => {
    // error when email address is empty or invalid format
    const passwordValidationMsg = getPasswordValidationMessage(password);
    if (passwordValidationMsg.length > 0) {
      return {
        passwordErrorDescription: passwordValidationMsg,
      };
    }

    // return empty error message when there are no validation errors
    return {
      passwordErrorDescription: "",
    };
  };

  resetValidatorErrors = () => {
    this.setState({
      validatorResults: {
        passwordErrorDescription: "",
      },
    });
  };

  // Run validators after a user stops typing by giving
  // them 500ms delay between each key stroke
  runValidatorsWithDelay = _.debounce(() => {
    const { password } = this.props;

    const validatorResults = this.checkForValidationErrors(password);
    if (validatorResults.passwordErrorDescription !== "") {
      this.setState({ validatorResults });
    }
  }, VALIDATION_DEBOUNCE_MS);

  onSubmitPassword = (event: SyntheticEvent<HTMLFormElement>): Promise<any> => {
    event.preventDefault();
    const { onSubmit, password, clearPassword } = this.props;

    // First check for validation error.
    const validatorResults = this.checkForValidationErrors(password);
    if (validatorResults.passwordErrorDescription !== "") {
      // validation error exist
      this.setState({ validatorResults });
      return Promise.reject(validatorResults.passwordErrorDescription);
    }

    // When there are no validation errors, proceed with authentication.

    // Disable button and show fetch progress animation.
    this.setStateForUiBeginFetch();

    // Call parent's onSubmit function.
    return onSubmit().then(({ wasSuccessful }) => {
      // Note(Kevin): Do not stop loading animation when login was successful as we redirect
      // to the dashboard. When error occurs, stop the loading animation so user can retype.
      if (wasSuccessful === false) {
        this.setStateForUiEndFetch();
        clearPassword(false); // pass in false to not clear the auth error message
        this.focusOnInput();
      }
    });
  };

  isThisComponentInView() {
    const { containerFlow, uiFlow } = this.props;
    return containerFlow === CONTAINER_LOGIN && uiFlow === LOGIN_FORM_PASSWORD;
  }

  onSubmitSsoLogin = (event: SyntheticEvent<HTMLFormElement>) => {
    event.preventDefault();
    const { emailAddress } = this.props;
    ssoLogin(emailAddress);
  };

  componentDidUpdate(prevProps: Props) {
    const prevPassword = prevProps.password;
    const password = this.props.password;
    const { uiFlow } = this.props;

    if (this.isThisComponentInView() && prevProps.uiFlow !== uiFlow) {
      if (window.loginInfo.authType === "PEPPERDATA_JWT_LDAP") {
        this.focusOnInput();
      } else if (
        window.loginInfo.authType === "PEPPERDATA_JWT" &&
        this.state.lastSSOUserNameCheck !== this.props.emailAddress
      ) {
        // focus on the password input while we fetch the sso status in the background
        this.focusOnInput();
        this.setState({
          lastSSOUserNameCheck: this.props.emailAddress,
        });
        fetch(
          "/api/auth/usernameIsSSO?username=" +
            encodeURIComponent(this.props.emailAddress),
          {
            method: "POST",
          }
        )
          .then((response) => response.json())
          .then((responseJSON) => {
            this.setState({
              isSsoSupported: responseJSON["isSSO"],
              isPasswordAllowed: responseJSON["isPasswordAllowed"],
            });
            if (responseJSON["isSSO"]) {
              this.focusOnSsoButton();
            }
          });
      }
    }
    // run validation on every password change
    if (prevPassword !== password && this.isThisComponentInView()) {
      this.resetValidatorErrors();
      this.runValidatorsWithDelay();
    }
  }

  goBackAndResetPassword = () => {
    const { clearPassword, backButtonOnClick } = this.props;
    clearPassword();
    this.resetValidatorErrors();
    backButtonOnClick();
  };

  render() {
    const { emailAddress, authErrorMessage } = this.props;
    const { isLoading, validatorResults, isSsoSupported, isPasswordAllowed } =
      this.state;
    const { passwordErrorDescription } = validatorResults;
    const passwordInputErrorDescription =
      authErrorMessage || passwordErrorDescription;
    const disabled = isLoading;

    const propsToChild = {
      ...this.props,
      isLoading,
      onSubmitPassword: this.onSubmitPassword,
      onSubmitSsoLogin: this.onSubmitSsoLogin,
      textInputRef: this.textInputRef,
      passwordInputErrorDescription,
      disabled,
    };

    const componentVisibility = this.isThisComponentInView()
      ? "visible"
      : "hidden";
    return (
      <div className="body-section" style={{ visibility: componentVisibility }}>
        <GoBackHeader
          goBackActionFunc={this.goBackAndResetPassword}
          textToDisplay={emailAddress}
          disabled={disabled}
        />

        {isSsoSupported ? (
          //$FlowFixMe
          <BodySectionPasswordSsoSupported
            ref={this.ssoBodySectionRef}
            isPasswordAllowed={isPasswordAllowed}
            {...propsToChild}
          />
        ) : (
          <Fragment>
            <Space height="44px" />
            <BodySectionPasswordView {...propsToChild} />
          </Fragment>
        )}
      </div>
    );
  }
}

export default BodySectionPassword;
