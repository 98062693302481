// Copyright (C) 2019 Pepperdata Inc. - All rights reserved.
// @flow

import React, { Fragment } from "react";
import Button from "../ui-elements/button";
import Space from "../ui-elements/space";
import TextInput from "../ui-elements/text-input";
import { isLDAPEnabled, onEnterOrSpaceDownPropsBuilder } from "../utils";
import type { Props } from "./login-form-body-section-password";

type AdditionalProps = {
  isLoading: boolean,
  onSubmitPassword: (SyntheticEvent<HTMLFormElement>) => Promise<any>,
  textInputRef: { current: null | React$ElementRef<typeof TextInput> },
  passwordInputErrorDescription: string,
  disabled: boolean,
  // Note(Kevin): We need to trim vertical spacing so the entire form fits inside the SSO container
  trimVerticalWhiteSpace: boolean,
};

const defaultProps = {
  trimVerticalWhiteSpace: false,
};

type CombinedProps = Props & AdditionalProps;

const BodySectionPasswordView = (props: CombinedProps) => {
  const {
    password,
    updatePassword,
    clearPassword,
    forgetPasswordOnClick,
    passwordInputErrorDescription,
    isLoading,
    onSubmitPassword,
    textInputRef,
    disabled,
    trimVerticalWhiteSpace,
  } = props;

  const label = "PASSWORD";
  const buttonText = "SIGN IN";
  const helpLinkText = "Forgot password?";
  const forgetPasswordOnClickIfNotDisabled = disabled
    ? () => {}
    : forgetPasswordOnClick;
  const helpLinkClassNames = disabled ? "disabled" : "";

  return (
    <Fragment>
      <form className="input-section" onSubmit={onSubmitPassword}>
        <TextInput
          autoComplete="current-password"
          ref={textInputRef}
          value={password}
          onChange={updatePassword}
          onClear={clearPassword}
          label={label}
          type="password"
          name="password"
          errorDescription={passwordInputErrorDescription}
          shouldFocusAfterTransitionDuration={true}
          disabled={disabled}
        />
        <Space height={trimVerticalWhiteSpace ? "4px" : "19px"} />
        <Button
          text={buttonText}
          buttonType="submit"
          isLoading={isLoading}
          disabled={disabled}
        />
        {!isLDAPEnabled() && (
          <React.Fragment>
            <Space height={trimVerticalWhiteSpace ? "2px" : "5px"} />
            <div className="password-help-container">
              <a
                className={`help-link ${helpLinkClassNames}`}
                onClick={forgetPasswordOnClickIfNotDisabled}
                {...onEnterOrSpaceDownPropsBuilder(
                  forgetPasswordOnClickIfNotDisabled
                )}
              >
                {helpLinkText}
              </a>
            </div>
          </React.Fragment>
        )}
      </form>
    </Fragment>
  );
};

BodySectionPasswordView.defaultProps = defaultProps;

export default BodySectionPasswordView;
