// Copyright (C) 2019 Pepperdata Inc. - All rights reserved.
// @flow

import React from "react";

export type StrengthBarConfig = {
  color: string,
  isVisible: () => boolean,
};

type Props = {
  strengthBarConfigs: Array<StrengthBarConfig>,
};

const StrengthBar = (props: Props) => {
  const { strengthBarConfigs } = props;
  let strengthColor = "transparent";
  let strengthIndexToShow = 0;

  // find the strength color
  strengthBarConfigs.forEach((config, index) => {
    const { color, isVisible } = config;
    if (isVisible()) {
      strengthColor = color;
      strengthIndexToShow = index;
    }
  });

  // render each strength as the same color
  const strengthsToRender = strengthBarConfigs.map(
    (strengthBarConfigs, index) => {
      const shouldShow = index <= strengthIndexToShow ? "show" : "hide";
      return (
        <div
          key={`strength-${index}`}
          className={`strength ${shouldShow}`}
          style={{ backgroundColor: strengthColor }}
        />
      );
    }
  );

  return <div className="pd-strength-bar">{strengthsToRender}</div>;
};

export default StrengthBar;
