// Copyright (C) 2019 Pepperdata Inc. - All rights reserved.
// @flow

import React from "react";
import Space from "../ui-elements/space";
import { PEPPERDATA_SUPPORT_URL } from "../constants";

const WelcomeStandaloneForm = () => {
  const supportLinkToRender = (
    <a href={PEPPERDATA_SUPPORT_URL} target="_blank" rel="noopener noreferrer">
      Pepperdata Support
    </a>
  );
  const message = window.loginInfo.welcomeMessage || (
    <div>
      Your Pepperdata dashboard is being created, which should take
      approximately 30 minutes. Please check back here then to log in. In the
      meantime, if you have any questions, please contact {supportLinkToRender}.
    </div>
  );
  return (
    <div className="pd-standalone-form">
      {/* Body Section */}
      <div className="body-section">
        <div className="input-section">
          <Space height="100px" />
          <div className="header">Signup Complete</div>
          <Space height="20px" />

          <div className="description">
            <div>Thank you for signing up for Pepperdata!</div>
            <Space height="10px" />
            {message}
          </div>
          <div style={{ marginTop: 12 }}>
            <a href="/logout">Logout</a> and try again later.
          </div>
        </div>
      </div>
    </div>
  );
};

export default WelcomeStandaloneForm;
