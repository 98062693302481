// Copyright (C) 2019 Pepperdata Inc. - All rights reserved.
// @flow

import _ from "lodash";
import React from "react";
import {
  CONTAINER_LOGIN,
  LOGIN_FORM_EMAIL,
  VALIDATION_DEBOUNCE_MS,
} from "../constants";
import Button from "../ui-elements/button";
import Space from "../ui-elements/space";
import TextInput from "../ui-elements/text-input";
import {
  getEmailAddressValidationMessage,
  isDemo,
  isLDAPEnabled,
} from "../utils";

type Props = {
  onSubmit: (SyntheticEvent<HTMLFormElement>) => void,
  updateEmailAddress: (SyntheticKeyboardEvent<HTMLInputElement>) => void,
  clearEmailAddress: () => void,
  emailAddress: string,
  containerFlow: string,
  uiFlow: string,
};

type State = {
  validatorResults: {
    emailAddressErrorDescription: string,
  },
};

class BodySectionEmail extends React.Component<Props, State> {
  textInputRef: { current: null | React$ElementRef<typeof TextInput> };

  constructor(props: Props) {
    super(props);

    this.state = {
      validatorResults: {
        emailAddressErrorDescription: "",
      },
    };

    this.textInputRef = React.createRef();
  }

  focusOnInput = () => {
    if (this.textInputRef.current !== null) {
      this.textInputRef.current.focusOnInput();
    }
  };

  isThisComponentInView() {
    const { containerFlow, uiFlow } = this.props;
    return containerFlow === CONTAINER_LOGIN && uiFlow === LOGIN_FORM_EMAIL;
  }

  checkForValidationErrors = (emailAddress: string) => {
    if (isLDAPEnabled()) {
      return {
        emailAddressErrorDescription: "",
      };
    } else {
      // error when email address is empty or invalid format
      const emailAddressValidationMsg =
        getEmailAddressValidationMessage(emailAddress);
      if (emailAddressValidationMsg.length > 0) {
        return {
          emailAddressErrorDescription: emailAddressValidationMsg,
        };
      }

      // return empty error message when there are no validation errors
      return {
        emailAddressErrorDescription: "",
      };
    }
  };

  resetValidatorErrors = () => {
    this.setState({
      validatorResults: {
        emailAddressErrorDescription: "",
      },
    });
  };

  // Run validators after a user stops typing by giving
  // them 500ms delay between each key stroke
  runValidatorsWithDelay = _.debounce(() => {
    const { emailAddress } = this.props;

    const validatorResults = this.checkForValidationErrors(emailAddress);
    if (validatorResults.emailAddressErrorDescription !== "") {
      this.setState({ validatorResults });
    }
  }, VALIDATION_DEBOUNCE_MS);

  onSubmit = (event: SyntheticEvent<HTMLFormElement>) => {
    event.preventDefault();
    const { emailAddress } = this.props;
    const parentOnSubmit = this.props.onSubmit;

    const validatorResults = this.checkForValidationErrors(emailAddress);
    if (validatorResults.emailAddressErrorDescription !== "") {
      // validation error exist
      this.setState({ validatorResults });
    } else {
      // no validation errors exist, proceed with parent's onSubmit fn
      parentOnSubmit(event);
    }
  };

  componentDidUpdate(prevProps: Props) {
    const prevEmailAddress = prevProps.emailAddress;
    const emailAddress = this.props.emailAddress;

    // run validation on every emailAddress change
    if (prevEmailAddress !== emailAddress) {
      this.resetValidatorErrors();
      this.runValidatorsWithDelay();
    }

    // when this UI is being viewed, focus on the input field
    const { containerFlow, uiFlow } = this.props;
    if (
      this.isThisComponentInView() &&
      (prevProps.containerFlow !== containerFlow || prevProps.uiFlow !== uiFlow)
    ) {
      this.focusOnInput();
    }
  }

  componentDidMount() {
    // on login page load, focus on email input
    const { containerFlow, uiFlow } = this.props;
    if (containerFlow === CONTAINER_LOGIN || uiFlow === LOGIN_FORM_EMAIL) {
      this.focusOnInput();
    }
  }

  render() {
    const { updateEmailAddress, emailAddress, clearEmailAddress } = this.props;
    const { validatorResults } = this.state;
    const { emailAddressErrorDescription } = validatorResults;

    const label = isLDAPEnabled() ? "USERNAME" : "EMAIL ADDRESS";
    const buttonText = isDemo() ? "LOGIN" : "NEXT";
    const componentVisibility = this.isThisComponentInView()
      ? "visible"
      : "hidden";

    return (
      <div className="body-section" style={{ visibility: componentVisibility }}>
        <Space height="65px" />

        <form
          className="input-section"
          onSubmit={this.onSubmit}
          autoComplete="off"
        >
          <TextInput
            ref={this.textInputRef}
            value={emailAddress}
            label={label}
            name="email"
            onChange={updateEmailAddress}
            onClear={clearEmailAddress}
            shouldFocusAfterTransitionDuration={true}
            errorDescription={emailAddressErrorDescription}
          />
          <Space height="19px" />
          <Button text={buttonText} buttonType="submit" />
        </form>
      </div>
    );
  }
}

export default BodySectionEmail;
