// Copyright (C) 2019 Pepperdata Inc. - All rights reserved.
// @flow

import React from "react";
import LoginPage from "./pages/login-page";

const App = () => {
  const currentPageComponent = <LoginPage />;

  return <div>{currentPageComponent}</div>;
};

export default App;
