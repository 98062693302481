// Copyright (C) 2018 Pepperdata Inc. - All rights reserved.
// @flow

import React from "react";

type Props = {
  isLoading: boolean,
};

const LoadingBar = (props: Props) => {
  const { isLoading } = props;

  const isLoadingClassName = isLoading ? "show" : "hide";

  return <div className={`pd-loading-bar ${isLoadingClassName}`} />;
};

export default LoadingBar;
