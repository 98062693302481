// Copyright (C) 2018 Pepperdata Inc. - All rights reserved.
// @flow

import _ from "lodash";
import React from "react";
import {
  COLOR_GREEN,
  COLOR_ORANGE,
  COLOR_RED,
  COLOR_YELLOW,
  CONTAINER_CREATE,
  CREATE_FORM_PASSWORD,
  DEFAULT_PASSWORD_REQUIREMENT_MSG,
} from "../constants";
import Button from "../ui-elements/button";
import Space from "../ui-elements/space";
import type { StrengthBarConfig } from "../ui-elements/strength-bar";
import TextInput from "../ui-elements/text-input";
import GoBackHeader from "./go-back-header";

type Props = {
  onSubmit: (SyntheticEvent<HTMLFormElement>) => Promise<any>,
  updatePassword: (SyntheticKeyboardEvent<HTMLInputElement>) => void,
  clearPassword: () => void,
  password: string,
  uiFlow: string,
  passwordRequirements: {
    regex: string,
    message: string,
  },
  createAccountErrorMessage: string,
  resetCreateAccountErrorMessage: () => void,
  isLoading: boolean,
  containerFlow: string,
  emailAddress?: string,
  backButtonOnClick: () => void,
  name?: string,
};

type State = {
  confirmPassword: string,
  validatorResults: {
    firstPasswordErrorDescription: string,
    confirmPasswordErrorDescription: string,
  },
};

class CreateAccountFormPassword extends React.Component<Props, State> {
  isFirstPasswordTouched: boolean;
  isConfirmPasswordTouched: boolean;
  runValidatorsTimeoutId: ?TimeoutID;
  textInputRef: { current: null | React$ElementRef<typeof TextInput> };

  constructor(props: Props) {
    super(props);

    this.state = {
      confirmPassword: "",
      validatorResults: {
        firstPasswordErrorDescription: "",
        confirmPasswordErrorDescription: "",
      },
    };

    this.isFirstPasswordTouched = false;
    this.isConfirmPasswordTouched = false;
    this.runValidatorsTimeoutId = null;
    this.textInputRef = React.createRef();
  }

  hasErrors = () => {
    const { firstPasswordErrorDescription, confirmPasswordErrorDescription } =
      this.state.validatorResults;

    if (
      firstPasswordErrorDescription.length > 0 ||
      confirmPasswordErrorDescription.length > 0
    ) {
      return true;
    }
    return false;
  };

  onSubmit = (event: SyntheticEvent<HTMLFormElement>) => {
    event.preventDefault();
    const parentOnSubmit = this.props.onSubmit;

    // Force run validators by setting inputs to be touched
    this.isFirstPasswordTouched = true;
    this.isConfirmPasswordTouched = true;

    this.runValidators(() => {
      // only run parent's onSubmit function if there are no validation errors
      if (this.hasErrors() === false) {
        parentOnSubmit(event);
      }
    });
  };

  updateFirstPassword = (event: SyntheticKeyboardEvent<HTMLInputElement>) => {
    const parentUpdatePassword = this.props.updatePassword;
    this.isFirstPasswordTouched = true;
    parentUpdatePassword(event);
  };

  updateConfirmPassword = (event: SyntheticKeyboardEvent<HTMLInputElement>) => {
    const { resetCreateAccountErrorMessage } = this.props;
    resetCreateAccountErrorMessage();

    const value = event.currentTarget.value;
    this.isConfirmPasswordTouched = true;
    this.setState({ confirmPassword: value });
  };

  clearConfirmPassword = () => {
    const { resetCreateAccountErrorMessage } = this.props;
    resetCreateAccountErrorMessage();
    this.setState({ confirmPassword: "" });
  };

  // Strength is fully dependant on length, not on password requirement
  strengthBarConfigOne: StrengthBarConfig = {
    color: COLOR_RED,
    isVisible: () => {
      const { password } = this.props;
      if (password.length >= 1) {
        return true;
      }
      return false;
    },
  };

  strengthBarConfigTwo: StrengthBarConfig = {
    color: COLOR_ORANGE,
    isVisible: () => {
      const { password } = this.props;
      if (password.length >= 6) {
        return true;
      }
      return false;
    },
  };

  strengthBarConfigThree: StrengthBarConfig = {
    color: COLOR_YELLOW,
    isVisible: () => {
      const { password } = this.props;
      if (password.length >= 8) {
        return true;
      }
      return false;
    },
  };

  strengthBarConfigFour: StrengthBarConfig = {
    color: COLOR_GREEN,
    isVisible: () => {
      const { password } = this.props;
      if (password.length >= 10) {
        return true;
      }
      return false;
    },
  };

  validatorFirstPassword = (forceCheck: boolean = false) => {
    const { password, passwordRequirements } = this.props;

    if (forceCheck === false && this.isFirstPasswordTouched === false) {
      return {};
    }

    // error when password is empty
    if (password.length === 0) {
      return {
        firstPasswordErrorDescription: "Your password must not be empty",
      };
    }

    // test for password requirement pattern
    const passwordRegex = new RegExp(passwordRequirements.regex);
    if (passwordRegex.test(password) === false) {
      return {
        firstPasswordErrorDescription:
          "Your password must match the above requirements",
      };
    }

    return {};
  };

  validatorConfirmPassword = (forceCheck: boolean = false) => {
    const { confirmPassword } = this.state;
    const { password } = this.props;

    if (forceCheck === false && this.isConfirmPasswordTouched === false) {
      return {};
    }

    // error when confirmPassword is empty
    if (confirmPassword.length === 0) {
      return {
        confirmPasswordErrorDescription: "Enter your confirmation password.",
      };
    }

    // error when password and confirmPassword do not match
    if (password !== confirmPassword) {
      return {
        confirmPasswordErrorDescription:
          "Your password and confirmation password do not match.",
      };
    }

    return {};
  };

  resetValidatorErrors = () => {
    this.setState({
      validatorResults: {
        firstPasswordErrorDescription: "",
        confirmPasswordErrorDescription: "",
      },
    });
  };

  // callback (function): Place any code that depends on the
  // validation errors in the callback function so that it will
  // run after the errors has been set to state. Reason for the
  // callback is because setState is asynchronous.
  runValidators = (callback?: () => void) => {
    const validators = [
      this.validatorFirstPassword,
      this.validatorConfirmPassword,
    ];

    let result = {};
    for (const validator of validators) {
      result = validator();

      if (Object.keys(result).length > 0) {
        const newValidatorResults = {
          ...this.state.validatorResults,
          ...result,
        };

        if (callback !== undefined) {
          this.setState({ validatorResults: newValidatorResults }, callback);
        } else {
          this.setState({ validatorResults: newValidatorResults });
        }
        break; // stop on the first encountered error, show one at a time.
      }
    }

    if (Object.keys(result).length === 0 && callback !== undefined) {
      callback();
    }
  };

  runValidatorsWithDelay = () => {
    this.resetValidatorErrors();

    // Prevent execution of past timeout
    if (this.runValidatorsTimeoutId !== null) {
      clearTimeout(this.runValidatorsTimeoutId);
    }

    // Run validators after a user stops typing by giving
    // them 500ms delay between each key stroke
    this.runValidatorsTimeoutId = setTimeout(() => {
      this.runValidators();
    }, 500);
  };

  focusOnInput = () => {
    if (this.textInputRef.current !== null) {
      this.textInputRef.current.focusOnInput();
    }
  };

  isThisComponentInView() {
    const { containerFlow, uiFlow } = this.props;
    return (
      containerFlow === CONTAINER_CREATE && uiFlow === CREATE_FORM_PASSWORD
    );
  }

  goBackAndResetPassword = () => {
    const { clearPassword, backButtonOnClick } = this.props;
    clearPassword();
    this.clearConfirmPassword();
    this.resetValidatorErrors();
    backButtonOnClick();
  };

  componentDidUpdate(prevProps: Props, prevState: State) {
    const prevPassword = prevProps.password;
    const prevConfirmPassword = prevState.confirmPassword;
    const password = this.props.password;
    const confirmPassword = this.state.confirmPassword;

    if (
      this.isThisComponentInView() &&
      (prevPassword !== password || prevConfirmPassword !== confirmPassword)
    ) {
      this.runValidatorsWithDelay();
    }

    // when this UI is being viewed, focus on the input field
    const { uiFlow } = this.props;
    if (this.isThisComponentInView() && prevProps.uiFlow !== uiFlow) {
      this.focusOnInput();
    }
  }

  render() {
    const {
      password,
      clearPassword,
      passwordRequirements,
      createAccountErrorMessage,
      isLoading,
      name = "",
    } = this.props;
    const { confirmPassword, validatorResults } = this.state;

    const { firstPasswordErrorDescription, confirmPasswordErrorDescription } =
      validatorResults;

    const passwordLabel = "PASSWORD";
    const confirmPasswordLabel = "CONFIRM PASSWORD";
    const buttonText = "SIGN UP";
    const componentVisibility = this.isThisComponentInView()
      ? "visible"
      : "hidden";

    let descriptionPassword = DEFAULT_PASSWORD_REQUIREMENT_MSG;
    if (passwordRequirements.message.length > 0) {
      descriptionPassword = `Requirements: ${passwordRequirements.message}`;
    }

    // if any validation error occurs, disable submit button
    const shouldDisableSubmitButton =
      _.keys(this.validatorFirstPassword(true)).length > 0 ||
      _.keys(this.validatorConfirmPassword(true)).length > 0;

    return (
      <div
        className="body-section password-ui-body-section"
        style={{ visibility: componentVisibility }}
      >
        <GoBackHeader
          goBackActionFunc={this.goBackAndResetPassword}
          textToDisplay={name}
          disabled={isLoading}
        />
        <form
          className="input-section"
          onSubmit={this.onSubmit}
          autoComplete="off"
        >
          <Space height="9px" />

          <TextInput
            ref={this.textInputRef}
            value={password}
            label={passwordLabel}
            onChange={this.updateFirstPassword}
            onClear={clearPassword}
            type="password"
            description={descriptionPassword}
            errorDescription={firstPasswordErrorDescription}
            strengthBarConfigs={[
              this.strengthBarConfigOne,
              this.strengthBarConfigTwo,
              this.strengthBarConfigThree,
              this.strengthBarConfigFour,
            ]}
            shouldFocusAfterTransitionDuration={true}
            disabled={isLoading}
          />

          <Space height="8px" />

          <TextInput
            value={confirmPassword}
            label={confirmPasswordLabel}
            onChange={this.updateConfirmPassword}
            onClear={this.clearConfirmPassword}
            type="password"
            errorDescription={confirmPasswordErrorDescription}
            disabled={isLoading}
          />

          <Space height="8px" />
          <Button
            text={buttonText}
            buttonType="submit"
            isLoading={isLoading}
            disabled={shouldDisableSubmitButton}
          />

          {/* Error message for creating account */}
          {createAccountErrorMessage && (
            <div className="submit-error-description">
              {createAccountErrorMessage}
            </div>
          )}
        </form>
      </div>
    );
  }
}

export default CreateAccountFormPassword;
