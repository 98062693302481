// Copyright (C) 2019 Pepperdata Inc. - All rights reserved.
// @flow

import React from "react";
import Space from "../ui-elements/space";
import PdLoadingIndicator from "../ui-elements/pd-loading-indicator";

const SsoInProgressStandaloneForm = () => {
  return (
    <div className="pd-standalone-form">
      {/* Body Section */}
      <div className="body-section">
        <div className="input-section text-center">
          <Space height="100px" />
          <div className="header">Sign In complete.</div>
          <Space height="10px" />
          <div className="description">
            <div>You will be redirected shortly.</div>
          </div>

          <PdLoadingIndicator />
        </div>
      </div>
    </div>
  );
};

export default SsoInProgressStandaloneForm;
