// Copyright (C) 2019 Pepperdata Inc. - All rights reserved.
// @flow

import React from "react";
import Space from "../ui-elements/space";
import { onEnterOrSpaceDownPropsBuilder } from "../utils";

type Props = {
  goBackActionFunc: () => void,
  textToDisplay: string,
  disabled: boolean,
};

const defaultProps = {
  disabled: false,
};

const GoBackHeader = (props: Props) => {
  const { goBackActionFunc, textToDisplay, disabled } = props;
  const goBackIfNotDisabled = disabled ? () => {} : goBackActionFunc;
  const classNames = disabled ? "disabled" : "";

  return (
    <div className={`pd-go-back-header-container ${classNames}`}>
      <div
        className="back-arrow-text"
        onClick={goBackIfNotDisabled}
        {...onEnterOrSpaceDownPropsBuilder(goBackIfNotDisabled)}
      >
        <i className="material-icons">arrow_back</i>
        <Space width={20} height={"auto"} />
        <div className="email-container">{textToDisplay}</div>
      </div>
    </div>
  );
};

GoBackHeader.defaultProps = defaultProps;

export default GoBackHeader;
