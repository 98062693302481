// Copyright (C) 2018 Pepperdata Inc. - All rights reserved.
// @flow

import React, { Fragment } from "react";
import LoadingBar from "./loading-bar";
import { SLIDE_UI_TRANSITION_DURATION } from "../constants";

type Props = {
  text: string,
  onClick?: () => void,
  className: string,
  buttonType: "button" | "submit" | "reset",
  disabled: boolean,
  isLoading: boolean,
};

const defaultProps = {
  className: "",
  buttonType: "button",
  disabled: false,
  isLoading: false,
};

type State = {};

class Button extends React.Component<Props, State> {
  static defaultProps = defaultProps;
  buttonRef: { current: null | HTMLButtonElement };

  constructor() {
    super();
    this.buttonRef = React.createRef();
  }

  focusOnButton = () => {
    if (this.buttonRef && this.buttonRef.current) {
      this.buttonRef.current.focus();
    }
  };

  // Delay the focus slightly to wait until the
  // UI slide transition has completed first. If
  // we do not wait, we will lose focus during the
  // UI transition.
  focusOnButtonWithDelay = () => {
    setTimeout(() => {
      this.focusOnButton();
    }, SLIDE_UI_TRANSITION_DURATION);
  };

  render() {
    const { text, onClick, className, buttonType, disabled, isLoading } =
      this.props;

    // buttonProps is used for spreading props to button element
    const buttonProps = {
      ref: this.buttonRef,
      className: `pd-button ${className}`,
      onClick,
      type: buttonType,
    };

    // disable the button of all actions when it is loading
    const isDisabledOrLoading = disabled || isLoading;

    return (
      <Fragment>
        <button {...buttonProps} disabled={isDisabledOrLoading}>
          {text}
        </button>
        <LoadingBar isLoading={isLoading} />
      </Fragment>
    );
  }
}

export default Button;
