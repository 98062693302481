import React from "react";

function footer() {
  return (
    <footer className="footer">
      <p>
        Pepperdata and the Pepperdata logo are registered trademarks of{" "}
        {<a href="http://www.pepperdata.com">Pepperdata, Inc.</a>}. Patent info:{" "}
        {
          <a href="https://www.pepperdata.com/legal/privacy-policy#patents">
            https://www.pepperdata.com/legal/privacy-policy
          </a>
        }
      </p>
    </footer>
  );
}

export default footer;
