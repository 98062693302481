// Copyright (C) 2018 Pepperdata Inc. - All rights reserved.
// @flow

import React, { Fragment } from "react";
import Button from "../ui-elements/button";
import TextInput from "../ui-elements/text-input";
import Space from "../ui-elements/space";
import {
  RESET_PASSWORD_TOKEN_KEY,
  TOKEN_PW_REQUIREMENTS_ERROR_MSG,
  VALIDATION_DEBOUNCE_MS,
  DEFAULT_PASSWORD_REQUIREMENT_MSG,
} from "../constants";
import { parseQueryString } from "../utils";
import {
  resetPassword,
  fetchPasswordRequirementsFromToken,
} from "../auth/auth-helpers";
import _ from "lodash";

type Props = {};

type State = {
  password: string,
  isGIDARealm: boolean,
  passwordToken?: string,
  isLoading: boolean,
  errorMessage: string,
  successfulPasswordChange: boolean,
  passwordRequirements: {
    regex: string,
    message: string,
  },
};

class ChangePasswordStandaloneForm extends React.Component<Props, State> {
  textInputRef: { current: null | React$ElementRef<typeof TextInput> };

  constructor(props: Props) {
    super(props);

    this.textInputRef = React.createRef();
    this.state = {
      password: "",
      isGIDARealm: false,
      passwordToken: undefined,
      isLoading: false,
      errorMessage: "",
      successfulPasswordChange: false,
      passwordRequirements: { regex: "", message: "" },
    };
  }

  getResetRequirements = () => {
    let passwordToken = "";
    let isGIDARealm = false;

    // get passwordToken
    const queryStringMap = parseQueryString(window.location.search);
    if (queryStringMap[RESET_PASSWORD_TOKEN_KEY]) {
      passwordToken = queryStringMap[RESET_PASSWORD_TOKEN_KEY];
    }

    // get password regex requirements
    this.fetchRegexRequirements(passwordToken);

    // get isGIDA value
    if (window.loginInfo && window.loginInfo.isGIDARealm) {
      isGIDARealm = window.loginInfo.isGIDARealm;
    }

    this.setState({
      passwordToken,
      isGIDARealm,
    });
  };

  formValidator = () => {
    const { passwordToken, isGIDARealm } = this.state;
    // when realm is not GIDA, it requires a reset token to reset password
    if (
      (passwordToken === undefined || passwordToken.length === 0) &&
      isGIDARealm === false
    ) {
      return "Reset password token not found in URL.";
    }
    return "";
  };

  inputValidator = () => {
    const { password, passwordRequirements } = this.state;
    // error when password is empty
    if (password.length === 0) {
      return "Your password must not be empty";
    }
    // test for password requirement pattern
    const passwordRegex = new RegExp(passwordRequirements.regex);
    if (passwordRegex.test(password) === false) {
      return "Your password must match the above requirements";
    }
    return "";
  };

  fetchRegexRequirements = async (passwordToken: string) => {
    this.setState({ isLoading: true });
    try {
      const response = await fetchPasswordRequirementsFromToken(passwordToken);
      if (
        _.isNil(response) ||
        _.isNil(response.regex) ||
        _.isNil(response.message)
      ) {
        throw TOKEN_PW_REQUIREMENTS_ERROR_MSG;
      }
      this.setState({ passwordRequirements: response });
    } catch (error) {
      this.setState({ errorMessage: TOKEN_PW_REQUIREMENTS_ERROR_MSG });
    }
    this.setState({ isLoading: false });
  };

  focusOnInput = () => {
    if (this.textInputRef.current !== null) {
      this.textInputRef.current.focusOnInput();
    }
  };

  updatePassword = (event: SyntheticKeyboardEvent<HTMLInputElement>) => {
    const value = event.currentTarget.value;
    this.setState({ password: value, errorMessage: "" });
  };

  clearPassword = () => {
    this.setState({ password: "", errorMessage: "" });
  };

  setStateForUiBeginFetch = () => {
    this.setState({ isLoading: true, errorMessage: "" });
  };

  setStateForUiEndFetch = (errorMessage: string) => {
    this.setState({ isLoading: false, errorMessage });
  };

  onSubmit = (event: SyntheticEvent<HTMLFormElement>) => {
    event.preventDefault();
    const { password, passwordToken = "" } = this.state;

    this.setStateForUiBeginFetch();
    return resetPassword(password, passwordToken)
      .then(() => {
        const errorMessage = "";
        this.setStateForUiEndFetch(errorMessage);
        this.setState({
          successfulPasswordChange: true,
        });
      })
      .catch((err) => {
        // Set error message to state so it is displayed on UI
        console.error(err);
        const errorMessage = "Error trying to reset password.";
        this.setStateForUiEndFetch(errorMessage);
      });
  };

  runInputValidatorWithDelay = _.debounce(() => {
    this.setState({ errorMessage: this.inputValidator() });
  }, VALIDATION_DEBOUNCE_MS);

  componentDidMount() {
    this.getResetRequirements();
    this.focusOnInput();
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (
      prevState.passwordToken !== this.state.passwordToken ||
      prevState.isGIDARealm !== this.state.isGIDARealm
    ) {
      this.setState({ errorMessage: this.formValidator() });
    }

    if (prevState.password !== this.state.password) {
      this.runInputValidatorWithDelay();
    }
  }

  render() {
    const {
      password,
      errorMessage,
      isLoading,
      successfulPasswordChange,
      passwordRequirements,
    } = this.state;

    const label = "NEW PASSWORD";
    const buttonText = "RESET PASSWORD";
    const shouldDisableForm = Boolean(this.formValidator()) || isLoading;
    const shouldDisableSubmitButton =
      shouldDisableForm ||
      Boolean(this.inputValidator()) ||
      isLoading ||
      Boolean(errorMessage);

    let descriptionPassword = DEFAULT_PASSWORD_REQUIREMENT_MSG;
    if (passwordRequirements.message.length > 0) {
      descriptionPassword = `Requirements: ${passwordRequirements.message}`;
    }

    const headerText = successfulPasswordChange
      ? "Password Successfully Updated"
      : "Enter New Password Below";

    const descriptionContent = successfulPasswordChange ? (
      <Fragment>
        You may <a href="/login">login here.</a>
      </Fragment>
    ) : (
      "Please enter your new password below."
    );

    return (
      <div className="pd-standalone-form">
        {/* Top Section */}
        <div className="top-section">
          <div>
            <span className="action">CHANGE YOUR PASSWORD</span>{" "}
            <span>with your account</span>
          </div>
        </div>

        {/* Body Section */}
        <div className="body-section">
          <form
            className="input-section"
            onSubmit={this.onSubmit}
            autoComplete="off"
          >
            <Space height="52px" />
            <div className="header">{headerText}</div>
            <Space height="4px" />
            <div className="description">{descriptionContent}</div>
            <Space height="20px" />

            {/* Do not display form inputs when password has been updated successfully */}
            {!successfulPasswordChange && (
              <Fragment>
                <TextInput
                  ref={this.textInputRef}
                  value={password}
                  label={label}
                  onChange={this.updatePassword}
                  onClear={this.clearPassword}
                  type="password"
                  description={descriptionPassword}
                  errorDescription={errorMessage}
                  shouldFocusAfterTransitionDuration={true}
                  disabled={shouldDisableForm}
                />

                <Space height="28px" />
                <Button
                  text={buttonText}
                  buttonType="submit"
                  isLoading={isLoading}
                  disabled={shouldDisableSubmitButton}
                />
              </Fragment>
            )}
          </form>
        </div>
      </div>
    );
  }
}

export default ChangePasswordStandaloneForm;
